import React from "react"

const ArrowRight = () => (
    <svg width="29" height="15" viewBox="0 0 29 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <desc>An arrow icon pointing to the right</desc>
        <path d="M22.3541 1.05884L28.3958 7.1005L22.3541 13.1422" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M28.3958 7.10046H0.604126" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default ArrowRight