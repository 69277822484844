import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ArrowRight from "../components/svg/arrowRight"

const SuccessPage = () => (

  	<Layout>

      <Seo 
        title="Thanks for contacting us" 
      />

      <section role="main" className="">
         <div className="container">
            <div className="row">
               <div className="sub-hero alt-hero">
                  <div className="sub-hero-text full">
                     <h1>We'll be in touch soon</h1>
                     <p>We'll get back to you in 24 hours. If it's urgent you can call us on <a href="tel:0292647574" className="bold no-line">+61 (2) 9264 7574</a></p>
                     <p>You can also follow our news and insights on <a href="https://www.linkedin.com/company/madclarity/about/" target="_blank" rel="noreferrer">Linkedin</a>.</p>
                     <div className="btn-row">
                        <Link to="/how-we-help" className="btn btn-lg">How we help</Link>
                        <Link to="/what-we-do" className="btn-link">
                           What we do
                           <ArrowRight />
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
           

  </Layout>
)

export default SuccessPage